function c(s) {
  document.readyState !== "loading" ? s() : document.addEventListener("DOMContentLoaded", () => s());
}
function d() {
  return new Promise(function(s) {
    c(s);
  });
}
function o(s, e, t, r) {
  return s.addEventListener(e, t, r), () => {
    s.removeEventListener(e, t, r);
  };
}
class f {
  constructor(e = "#errorText", t = "#error") {
    this.errLabel = null, this.errDisplay = null, this.hasFocus = !1, this.errLabel = document.querySelector(e), this.errDisplay = document.querySelector(t);
  }
  get canDisplayError() {
    return this.errLabel && this.errDisplay;
  }
  checkError() {
    if (!this.canDisplayError)
      throw new Error("Error element not present");
    if (this.errLabel && this.errLabel.innerHTML && this.errDisplay) {
      this.errDisplay.style.display = "";
      const e = this.errLabel.getAttribute("for");
      if (e) {
        const t = document.getElementById(e);
        t && t.value && (t.focus(), this.hasFocus = !0);
      }
    } else
      this.errDisplay && (this.errDisplay.style.display = "none");
  }
  setInitialFocus(e) {
    if (this.hasFocus)
      return;
    const t = document.getElementById(e);
    t && /^\s*$/.test(t.value) && (t.focus(), this.hasFocus = !0);
  }
  setError(e, t) {
    if (!this.canDisplayError)
      throw new Error("Error element not present");
    e.focus(), this.errLabel && (t && (this.errLabel.innerHTML = t), this.errLabel.setAttribute("for", e.id)), this.errDisplay && (this.errDisplay.style.display = "");
  }
  clearError() {
    if (!this.canDisplayError)
      throw new Error("Error element not present");
    this.errLabel && (this.errLabel.innerHTML = "", this.errLabel.setAttribute("for", ""), this.errDisplay && (this.errDisplay.style.display = "none"));
  }
  clearValidationErrors() {
    document.querySelectorAll("div.error > span").forEach((t) => {
      t.innerHTML = "";
    });
  }
  setValidationError(e, t) {
    const r = e.id, i = document.querySelector(
      `#${r} ~ div.error > span`
    );
    i && (i.innerHTML = t);
  }
  focus(e) {
    const t = document.getElementById(e);
    t && t.focus();
  }
  hasClass(e, t) {
    return e.className.match(new RegExp("(\\s|^)" + t + "(\\s|$)"));
  }
  addClass(e, t) {
    this.hasClass(e, t) || (e.className += " " + t);
  }
  removeClass(e, t) {
    if (this.hasClass(e, t)) {
      const r = new RegExp("(\\s|^)" + t + "(\\s|$)");
      e.className = e.className.replace(r, " ");
    }
  }
  showHint(e) {
    if (e.value && e.value !== "") {
      const t = e.previousSibling;
      t.style.display = "none";
    } else {
      const t = e.previousSibling;
      t.style.display = "";
    }
  }
}
class p {
  constructor(e, t, r, i, l, n, a = null, h = "#errorText", u = "#error") {
    this.form = e, this.submitButton = t, this.loader = r, this.errors = i, this.validateForm = n, this.errorsConfig = a, this.errTextElementSelector = h, this.errDisplayElementSelector = u, this.requiredFields = [], this.optionalFields = [], this.clearHandlers = [], this.inputUtil = new f(
      this.errTextElementSelector,
      this.errDisplayElementSelector
    ), this.setInputFields(l), console.log(this.requiredFields), console.log(this.optionalFields), this.addEventListeners();
  }
  setInputFields(e) {
    e ? Array.isArray(e) ? (this.requiredFields = e, this.optionalFields = []) : "requiredFields" in e ? (this.requiredFields = e.requiredFields, this.optionalFields = e.optionalFields) : (this.requiredFields = [e], this.optionalFields = []) : (this.requiredFields = [], this.optionalFields = []);
  }
  async addEventListeners() {
    if (await d(), this.form) {
      const e = o(this.form, "keypress", (t) => {
        t.key === "Enter" && (t.preventDefault(), this.submitRequest());
      });
      this.clearHandlers.push(e);
    }
    if (this.submitButton) {
      const e = o(
        this.submitButton,
        "keypress",
        (r) => {
          r.key === " " && (r.preventDefault(), this.submitRequest());
        }
      );
      this.clearHandlers.push(e);
      const t = o(this.submitButton, "click", () => {
        this.submitRequest();
      });
      this.clearHandlers.push(t);
    }
    this.requiredFields.forEach((e) => {
      this.listenInputField(e, !0);
    }), this.optionalFields.forEach((e) => {
      this.listenInputField(e, !1);
    });
  }
  listenInputField(e, t) {
    if (e) {
      this.errorsConfig?.errorElement && this.errorsConfig?.on === "input" && o(
        e,
        "input",
        () => {
          this.errorsConfig.errorElement.innerHTML = "";
        },
        { once: !0 }
      );
      const r = o(e, "input", () => {
        t ? this.checkSubmitButtonState(e) : this.checkSubmitButtonState();
      });
      this.clearHandlers.push(r);
    }
  }
  dispose() {
    this.clearHandlers.forEach((e) => e());
  }
  [Symbol.dispose]() {
    this.dispose();
  }
  validate(e, t) {
    if (typeof this.validateForm == "function")
      return this.validateForm(e, t);
    const r = this.validateForm.failEarly ?? !0;
    let i = !0;
    for (const l of this.validateForm.validators)
      if (l.validator(l?.input?.value)) {
        if (t ? (l.onError?.(), this.inputUtil.setValidationError(l.input, l.error)) : this.inputUtil.clearValidationErrors(), i &&= !1, r)
          return !1;
      } else
        i &&= !0;
    return i;
  }
  submitRequest() {
    this.errorsConfig?.errorElement && this.errorsConfig?.on === "submit" && (this.errorsConfig.errorElement.innerHTML = ""), this.validate(this.inputUtil, this.errors) && (this.loader.style.display = "inline-block", typeof this.form.submit == "function" && this.form.submit(), typeof window.history.replaceState == "function" && window.history.replaceState(null, "", window.location.href));
  }
  checkSubmitButtonState(...e) {
    const t = this.validate(this.inputUtil), r = e.every(
      (i) => !!(i.value && i.value.length > 0)
    );
    t && r ? (this.inputUtil.clearValidationErrors(), this.submitButton.classList.remove("disabled"), this.submitButton.removeAttribute("disabled")) : (this.submitButton.classList.add("disabled"), this.submitButton.setAttribute("disabled", "disabled"));
  }
}
export {
  p as ActionFormHelper,
  f as InputUtil
};
